.post-modal{
    background-color: #E2F0FF;
    color: #000;
    width: 90%;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}
.post-modal-title{
    font-family: "Montserrat", sans-serif;
    border:none;
}
.post-modal-footer{
    font-family: "Montserrat", sans-serif;
    border-top: 1px solid #aaa!important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
}
.close{
    color: #fff;
    position: absolute;
    top: 1%;
    right: 1%;
    z-index: 1;
}
.close_design{
    width: 100px;
    height: 100px;
    background-color: #00A8FF;
    border-radius: 70%;
    position: absolute;
    top:-16%;
    right: -14%;
    z-index:0;
}
.term{
    color: #00A8FF;
}
.code{
    width: 175px !important;
    letter-spacing: 1rem;
    font-size: 18px;
    font-weight: 550!important;
    text-align: center; 
}