.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 60px;
  }
  
  .btn-play {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 0 15px 25px;
    border-color: transparent transparent transparent #000000;
    cursor: pointer;
  }
  
  .btn-stop {
    height: 27px;
    width: 23px;
    border-left: 10px solid rgb(0, 0, 0);
    border-right: 10px solid rgb(0, 0, 0);
    cursor: pointer;
  }
  