.containerClass {
  border: 0px;
}
.inputClass,
.flag-dropdown {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #00a8ff !important;
}
.adjust-input {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow: hidden !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text-icon-div {
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text-icon-div img {
  width: 90%;
}
.text-icon-div h3 {
  font-family: Montserrat, sans-serif !important;
  color: #00a8ff;
  font-size: 24px;
}
.text-icon-div h6 {
  font-family: Montserrat, sans-serif !important;
  opacity: 60%;
  font-size: 12px;
}
.text-div {
  width: 100vw;
  margin-top: 50px;
}
.phone-div {
  margin-top: 30px;
  position: relative;
}

.phone-div button {
  font-family: Montserrat, sans-serif !important;
  padding: 13px 0px;
  color: white;
  background-color: #00a8ff !important;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  margin-top: 50px !important;
  border-radius: 50px;
  border: 2px solid #00a8ff;
}

.phone-div button:hover {
  border: 2px solid #00a8ff;
  color: #000 !important;
  background-color: transparent !important;
}
.swal-icon {
  border-color: #00a8ff !important;
}
.swal-icon--error__line {
  background-color: #00a8ff;
}

.swal-text,
.swal-title {
  text-align: center;
  font-size: 14px !important;
  font-family: Montserrat, sans-serif !important;
}
.swal-icon--info:after,
.swal-icon--info:before {
  background-color: #00a8ff;
}
.swal-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swal-button {
  /* width: 40%; */
  display: block;
  font-size: 18px;
  background-color: #00a8ff;
  color: #fff;
  font-family: Montserrat, sans-serif !important;
}
/* media query for website */
@media only screen and (min-width: 1024px) {
  .adjust-input {
    /* margin: 20px 0 !important;
    padding: 0 !important;
    left: 25%;
    right: 25%; */
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .text-icon-div img {
    width: 200px !important;
  }
  .text-icon-div h3 {
    font-family: Montserrat, sans-serif !important;
    color: #00a8ff;
    font-size: 20px;
  }
  .text-icon-div h6 {
    font-family: Montserrat, sans-serif !important;
    opacity: 60%;
    font-size: 12px;
  }
  .text-div {
    width: 100vw;
    margin-top: 50px;
  }
  .phone-div {
    margin-top: 30px;
    position: relative;
  }

  .phone-div button {
    font-family: Montserrat, sans-serif !important;
    padding: 13px 0px;
    color: white;
    background-color: #00a8ff !important;
    width: 100%;
    font-size: 12px;
    margin-top: 50px !important;
    border-radius: 50px;
  }
}
/* OTP Style  */
:root {
  --ReactInputVerificationCode-itemWidth: 3rem!important; /* Define the width of each input field */
}
.OTP-modal{
  position: relative;
  min-height: 100%;
  width: 100%;
  z-index: 100;
}
.OPT-sub-modal{
  z-index: 1000;
  position: absolute;
  bottom: 0px;
  background-color: white;
  height: 520px;
  border-radius: 30px 30px 0 0;
  width: 100%;
  box-shadow: 0px 3px 10px 7px rgba(59, 59, 59,0.6);
 
}
.OPT-sub-modal .ReactInputVerificationCode__item.is-active {
  border-bottom: 1px solid #0361e4;
}
.OPT-sub-modal .ReactInputVerificationCode__item {
  align-items: center;
  border: 0px;
  border-top: 0px;
  box-shadow: none!important;
  border-bottom: 1px solid grey;
  border-radius: 0px;
}
.OPT-sub-modal p{
  margin: 0 2px;
}
.OTP_Validate .ReactInputVerificationCode__item {
  align-items: center;
  box-shadow: none!important;
  border: 1px solid rgb(178, 0, 0);
  border-radius: 0px;
}
.ReactInputVerificationCode__container{
  margin: auto;
}
.OPT-sub-modal .lockStyle{
  margin-top: 50px;
  margin-bottom: 30px;
  width: 130px;
  height: 100px;
}
.send_again_btn{
  margin-top: 20px;
}
.submitBtn{
  padding: 13px!important;
  border-radius: 50px!important;
  width: 80%;
  margin-top: 20px;
  background-color: cornflowerblue!important;
  border: 0px!important;
  padding: 10px;
  font-size: 18px!important;
}
/* OTP Style  */