.profile-main-container{
  height: 100vh;
  overflow: hidden !important;;
}
.profile-img-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat, sans-serif !important;
  margin: 20px 0 50px 0 !important;
  height: 20%;
}
.left-arrow {
  position: absolute;
  color: #fff;
  z-index: 100 !important;
  font-size: 24px;
  top: 20px;
  left: 20px;
}
.user-profile-img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 3px solid #00a8ff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  z-index: -10 !important;
  padding: 0;
  margin: 0;
  object-fit: cover !important;
  position: relative !important;
}
.user-information-container {
  padding: 20px 25px;
  background-color: #f1f0f0;
  border-radius: 0px 40px 0px 0px;
  width: 100vw;
  margin-top: -30px;
  z-index: 100 !important;
  height: 80%
}
.user-name {
  font-size: 14px;
  color: #374750;
}
.user-phone {
  font-family: Montserrat, sans-serif !important;
  font-size: 14px;
  color: #374750;
}
.edit-btn {
  font-family: Montserrat, sans-serif !important;
  color: #fff;
  border: 1px solid #00a8ff;
  padding: 5px 20px;
  font-size: 12px;
  color: #00a8ff;
  border-radius: 8px;
  background-color: transparent;
  white-space: nowrap;
  z-index: 100 !important;
}
.plan-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-plan-title {
  font-family: Montserrat, sans-serif !important;
  font-size: 14px;
  color: #374750;
}
.plan-btn {
  font-family: Montserrat, sans-serif !important;
  font-size: 12px;
  background-color: #f1f0f2;
  color: #00a8ff;
  border-radius: 8px;
  border: 1px solid #00a8ff;
  padding: 5px 15px;
  white-space: nowrap;
}
.details-container {
  font-family: Montserrat, sans-serif !important;
  background-color: #00a8ff;
  padding: 15px 20px;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  position: relative;
  margin: 20px 0 ;
}
.details-container::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 28px;
  transform: translate(120px, -14px) skew(-150deg);
  transform: translate(140px, -14px) skew(-150deg);
  background-color: #f1f0f2;
  transition: all 1s;
}
.details-container::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 28px;
  transform: translate(120px, 14.2px) skew(150deg);
  transform: translate(140px, 14.2px) skew(150deg);
  background-color: #f1f0f2;
  transition: all 1s;
}

.left-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .currency {
  font-size: 12px;
  color: #fff;
  padding-left: 10px;
} */

.right-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-settins-tab-container {
  font-family: Montserrat, sans-serif !important;
  background-color: #fff;
  font-weight: bold;
  padding: 20px;
  margin-top: 10px;
  border-radius: 20px;
  align-items: center;
}
.divider {
  width: 95%;
  margin-right: 25%;
  margin-left: 3%;
}
.icon-title {
  font-size: 10px;
  color: #9599b3;
  padding-top: 8px;
}
.point_deal_btn{
  font-family: Montserrat, sans-serif !important;
  background-color: #00a8ff;
  padding: 15px 20px;
  border-radius: 50px;
  margin-top: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  text-align: center!important;
  margin: 20px 0;
}
/* for laptop */
@media only screen and (min-width: 1024px) {
  .profile-main-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .profile-img-container{
    width: 40vw;
  }
  .user-information-container{
    width: 40vw;
  }
  .user-list-main-container{
    width: 40vw!important;
  }
}
