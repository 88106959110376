body,
html {
  margin: 0 !important;
  padding: 0 !important;
  /* height: 100% !important; */

  box-sizing: border-box;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400&display=swap");

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.swal2-image{
  margin: 0!important;
}
.swal2-popup{
  padding: 0px!important;
}

.swal2-input{
  margin: 10px!important;
  width: 50px!important;
}
.swal2-title{
  font-weight: normal;
}
.swal2-confirm{
  margin-bottom: 20px;
}
.star-color{
  color: yellow;
}
.star-color-light{
  color: rgb(240, 238, 238);
}
.swal2-close{
  width: 1em!important;
  border-radius: 50px!important;
  margin-top: -33px!important;
  color: #ffffffa8!important;
  display: flex!important;
  padding-top: -4px!important;
  padding-bottom: 5px!important;
  height: 1em!important;
}
