.notification-top-container{
  margin: 0;
  padding: 0;

}

.notification-top-inner-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 0px 0px 0px 50px;
    padding: 20px 20px 0px 20px;
    border-bottom: 1px solid #b2afaf;
  
}

.notification-image-container{
    width: 60px;
    height: 60px;
    margin-right: 20px;
   
}
.notification-image-container>img{
    border:2px solid #fff;
    border-radius: 100%;
}

.notification-text-container{
 width: 100%;
 height: auto;
}
