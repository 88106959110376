.videoContainer {
  margin: 0px;
  padding: 0;
  overflow: hidden;
  background-color: #00b4d8 !important;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.video_card {
  width: 90% !important;
  height: 80% !important;
  background-color: #fff;
  border-radius: 25px !important;
  flex-direction: column;
}
.video_card_header {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: transparent !important;
}
.video-container {
  background-color: black;
  padding: 0;
  width: 100%!important;
  height: 25vh;
}
.x-icon {
  z-index: 999;
  font-size: 28px;
  margin: 0;
  color: #fff ;
  position: absolute;
  top: 13px;
  left: 10px;
}
.video {
  width: 100%;
  height: 100%;
}
.vjs-tech {
  background-color: #00b4d8 !important;
  border: none !important;
  box-shadow: none !important;
  border-width: 0px !important;
  width: 100% !important;
  height: 100% !important;
  padding-right: 1px !important;
  
}
.video-js {
  background-color: transparent !important;
  overflow: hidden;
}
.vjs-poster{
  background-size: 100%!important;
  height: auto !important;
}

.video-bottom-container > h6 {
  font-size: 14px;
} 
.back-container{
  background-color: #00b4d8!important;
  color: #ddd;
  margin: 0;
  padding: 0;
}
.video-text {
  font-size: 15px !important;
  font-family: Montserrat, sans-serif !important;
  overflow: scroll !important;
}

@media screen and (min-height: 667px) {
  .video-text {
    font-size: 14px !important;
    font-family: Montserrat, sans-serif !important;
    overflow: scroll !important;
  }
}
@media screen and (min-height: 500px) {
  .video-text {
    font-size: 13px !important;
    font-family: Montserrat, sans-serif !important;
    overflow: scroll !important;
  }
}
@media screen and (min-height: 800px) {
  .video-text {
    font-size: 15px !important;
    font-family: Montserrat, sans-serif !important;
    overflow: scroll !important;
  }
}
@media screen and (min-height: 900px) {
  .video-text {
    font-size: 16px !important;
    font-family: Montserrat, sans-serif !important;
    overflow: scroll !important;
  }
}
@media screen and (min-height: 1000px) {
  .video-text {
    font-size: 16px !important;
    font-family: Montserrat, sans-serif !important;
    overflow: scroll !important;
  }
}

@media only screen and (min-width: 1024px) {
  .video-main-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #00b4d8;

    margin: 0;
    padding: 0;
  }

  .videoContainer{
    width: 40vw!important;

    border-left: 1px solid #ddd !important;
    border-right: 1px solid #ddd !important;
  }
  .video_card{
    width: 320px!important;
    height: 520px!important;
  }
}
