.specials_main_container{
  transform: translateY(-35px);
  margin-bottom: 50px!important;
  overflow-x: hidden;
}
.section-container {
  align-items: center;
  border-radius: 0px 0px 0px 40px;
  padding: 10px !important;
  /* box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px; */
}
.date {
  font-size: 10px;
  color: #fff;
}
.book_title {
  width: 100% !important;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  line-height: 22px !important;
}
.book_img {
  width: 100px !important;
  height: 100px !important;
  float: right;
}
.sub-title {
  font-size: 12px;
  color: #fff !important;
  padding: 0 !important;
  margin: 0 !important;
}
.save-icon-1 {
  color: #fff;
  float: right;
}
.post-type-icon {
  color: #fff;
  float: right;
  border: 2px solid #fff;
  border-radius: 100% !important;
  width: 25px;
  height: 25px;
  padding: 3px 4px;
  margin: 5px 10px;
}
.sub-container {
  display: flex;
  align-items: center;
  justify-content: left;
}
.saved_icon {
  background-color: #fff;
  border: 1px solid #00a8ff !important;
  border-radius: 50%;
  color: #00a8ff !important;
  padding: 3px 6px !important;
  margin-right: 6px;
  width: 22px;
  height: 22px;
}
.post_icon{
  margin: 7px 15px 7px 7px;
  color: #fff!important;
}
.specials_container .filter-container{
  width: 100%;
  height: 32vh;
  padding-top: 35px;
}
.specials_container .slick_title{
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
}
.specials_container .btn-slick{
  font-family: "Montserrat", sans-serif!important;
  background-color:white!important;
  border: 1px solid #5A5387!important;
  border-radius: 40px!important;
  font-size: 12px!important;
  font-weight: 500;
  border: none;
  /* box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%); */
}
.specials_container .btn-slick.active{
  background-color:#5A5387!important;
  border-radius: 40px!important;
  font-weight: 500;
  border: none;
  /* box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%); */
}
.tab-container{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}
@media screen and (min-height: 600px) {
  .specials_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 28vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:16px 20px 5px 20px;
    font-size: 12px;
    line-height: 22px;
  }
}
@media screen and (min-height: 700px) {
  .specials_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 25vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:16px 20px 5px 20px;
    font-size: 12px;
    line-height: 22px;
  }
}
@media screen and (min-height: 800px) {
  .specials_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 22vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;
  }
}
@media screen and (min-height: 850px) {
  .specials_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 24vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;

  }
}
@media screen and (min-height: 890px) {
  .specials_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 20vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;

  }
}
@media screen and (min-height: 900px) {
  .specials_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 20vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;

  }
}
@media screen and (min-height: 1000px) {
  .specials_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 19vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;

  }
}
@media screen and (min-height: 1300px) {
  .specials_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 16vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;

  }
}
@media screen and (max-width: 280px) {
  .specials_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 32vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;
  }
}

@media screen and (min-width: 1024px){
  .specials_container{
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 0;
  }
  .specials_main_container {
    width: 40vw; 
    overflow: hidden !important;
    border-left: 1px solid #ccc!important;
    border-right: 1px solid #ccc!important;
    margin-bottom: 0px !important;
  }
  .date{
    font-size: 10px!important;
    color: #fff!important;
    margin: 0;
    padding: 0;
  }
  .book_title {
    font-family: Montserrat, sans-serif !important;
    color: #fff;
    line-height: 22px !important;
    font-size: 11px !important;
  }
  .book_img {
    width: 80px !important;
    height: 80px !important;
    float: right;
  }
  .saved_icon {
    color: #00a8ff !important;
    background-color: #fff;
    border: 1px solid #00a8ff;
    border-radius: 50%;
    padding: 5px 0px !important;
    width: 20px;
    height: 20px;
    margin: 0 5px !important;
  }
  .save_icon_1 {
    color: #fff;
    float: right;
    font-size: 16px!important;
    margin: 0 5px !important;
  }
  .sub_title{
    font-size: 10px!important;
  }
  .post_icon{
    margin: 3px 7px;
  }
  .specials_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 33vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;
  }
}
@media screen and (min-width: 1200px) {
  .specials_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 25vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;
  }
}
@media screen and (min-width: 1300px) {
  .specials_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 30vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .specials_container .slick-slider{
    margin-top: 10px;
  }
  .specials_container .slick_title{
    padding: 0px 15px;
    padding-top: 17px;
  }
}
@media (min-width: 992px) and (max-width: 1023px) {
  .specials_container .slick-slider{
    margin-top: 37px;
  }
  .specials_container .slick_title{
    padding: 0px 20px;
    padding-top: 37px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .specials_container .slick-slider{
    margin-top: 37px;
  }
  .specials_container .slick_title{
    padding: 0px 20px;
    padding-top: 37px;
  }
}
@media (min-width: 678px) and (max-width: 768px) {
  .specials_container .slick-slider{
    margin-top: 32px;
  }
  .specials_container .slick_title{
    padding: 0px 20px;
    padding-top: 32px;
  }
}
@media (min-width: 462px) and (max-width: 677px) {
  .specials_container .slick-slider{
    margin-top: 18px;
  }
  .specials_container .slick_title{
    padding: 0px 20px;
    padding-top: 24px;
  }
}
@media (min-width: 392px) and (max-width: 461px) {
  .specials_container .slick-slider{
    margin-top: 7px!important;
  }
  .specials_container .slick_title{
    font-size: 12px;
    padding: 0px 25px;
    padding-top: 15px!important;
  }
  .specials_container .btn-slick{
    font-size: 11px!important;
    /* box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%); */
  }
}
@media (min-width: 328px) and (max-width: 391px) {
  .specials_container .slick-slider{
    margin-top: 16px!important;
  }
  .specials_container .slick_title{
    padding: 0px 20px;
    padding-top: 20px;
    font-size: 10px;
  }
  .specials_container .btn-slick{
    font-size: 11px!important;
    /* box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%); */
  }
}
@media (min-width: 307px) and (max-width: 328px) {
  .specials_container .slick-slider{
    margin-top: 17px;
  }
  .specials_container .slick_title{
    padding: 0px 20px;
    padding-top: 17px;
    font-size: 10px;
  }
  .specials_container .btn-slick{
    font-size: 9px!important;
    /* box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%); */
  }
}
@media (min-width: 250px) and (max-width: 306px) {
  .specials_container .slick-slider{
    margin-top: 0px;
  }
  .specials_container .slick_title{
    padding: 0px 20px;
    padding-top: 14px;
    font-size: 10px;
  }
  .specials_container .btn-slick{
    font-size: 9px!important;
    /* box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%); */
  }
}
.star-color{
  color: yellow;
}
.star-color-light{
  color: rgb(240, 238, 238);
}