.about-lifex-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.carousel-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100vw;
  height: 80vh;
  padding: 5px 10px;
}
.skip-btn {
  z-index: 1000;
  font-family: Montserrat, sans-serif !important;
  background-color: #82d2f9;
  outline: none;
  padding: 10px 30px;
  border-radius: 50px;
  border: none;
  color: #fff;
  float: right;
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}

@media only screen and (min-width: 1280px) {
  .about-lifex-main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  .carousel-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    /* padding: 5px 10px; */
    margin: 0px !important;
    position: absolute;
  }
  .about-lifex-main-container
    > .carousel-wrapper
    > .carousel
    > .carousel-inner {
    width: 100% !important;
    height: auto !important;
    margin: 0px;
    padding: 0px;
  }

  .about-lifex-main-container
    > .carousel-wrapper
    > .carousel
    > .carousel-inner
    > .carousel-item {
    width: 30% !important;
    height: 100% !important;

    margin-left: 35%;
  }

  .skip-btn {
    z-index: 1000;
    font-family: Montserrat, sans-serif !important;
    background-color: #82d2f9;
    outline: none;
    padding: 10px 40px;
    border-radius: 50px;
    border: none;
    font-size: 14px;
    color: #fff;
    float: right;
    -webkit-box-shadow: 0px 10px 13px -7px #000000,
      5px 5px 15px 5px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    margin-bottom: 20px;
  }
}
