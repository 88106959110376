.lifex-icon img{
    width: 150px;
    height: 150px;
    border: 1px solid rgb(88, 87, 87);
    border-radius: 50%;
}
.rating-screen{
    position: relative;
    width: 100%;
    transform:scale(0.88);
}
.lifex-icon{
    z-index: 2;
    width: 100%;
    position: absolute;
    margin-top: 150px;
}
.rating-card{
    border-radius: 30px!important;
    background-color: #BDE1F3!important;
    width: 375px;
    z-index: 1;
    margin-top: 230px;
}
.rating-card .card-body h3{
    padding-top: 90px;
    font-size: 25px;
    color: rgb(81, 81, 81);
}
.star-container .star{
    margin-top: 30px;
    color: rgb(98, 98, 98);
    font-size: 58px;
    padding: 10px;
    cursor: pointer;
}
.star-container{
    margin-bottom: 20px;
}
.cancel-button-container{
    width: 50%;
    border-right: 1px solid white;
}
.submit-button-container{
    width: 50%;
    border-left: 1px solid white;
}
.cancel-button-container button{
    box-shadow: none;
}
.card-footer{
    border-top: 2px solid white!important;
    background-color: #BDE1F3!important;
    border-bottom-left-radius: 50px!important;
    border-bottom-right-radius: 50px!important;
    padding: 0px!important;
}
.rating-card .btn{
    padding: 15px!important;
}
.star-text{
    margin-bottom: 20px;
}
.star-color{
    color: yellow!important;
}
@media (min-width: 600px) and (max-width : 750px) {
    .rating-screen{
        scale: 1.2;
    }
}
@media (min-width: 300px) and (max-width: 380px) {
    .rating-screen{
        scale: 0.8;
    }
}
@media (min-width: 260px) and (max-width: 300px) {
    .rating-screen{
        scale: 0.6;
    }
}
@media (max-height: 780px) and (min-height: 555px) {
    .rating-screen{
        margin-top: -100px;
    }
}