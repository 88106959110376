.user-list-main-container {
  position: relative;
  overflow: hidden;
}
.user-list-modal {
  font-family: Montserrat, sans-serif !important;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex !important;
  justify-content: center;
  align-items: flex-end;
  align-self: baseline;
}
.modal-header-1 {
  width: 100vw;
  height: 80px;
  background-color: #fff;
  border: none !important;
  display: flex;
  flex-direction: column;
  border-radius: 50px 0px 0px 0px !important;
}
.modal-header > h4 {
  font-size: 16px;
  color: #00a8ff;
  font-weight: bolder;
  text-align: left;
  margin-top: -30px;
  margin: 0;
  align-self: flex-start;
  padding: 5px 20px;
}
.modal-close-btn {
  border: 1px solid #ccc;
  background-color: #ccc;
  padding: 2px 20px;
  border-radius: 10px;
  margin: auto;
}
.modal-body-1 {
  background-color: #fff;
  border: none !important;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 340px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.modal-content {
  border: none !important;
  width: 100vw !important;
  background-color: transparent !important;
  margin-bottom: -10px;
  scrollbar-width: none !important;
}
.modal-content::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
}

.user-left-container {
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.user-left-container > p {
  padding: 0;
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
}
.user-left-container > h6 {
  margin: 0;
  padding: 0;
  padding-left: 8px;
  font-size: 14px;
}

.user-left-container > img {
  width: 50px;
  height: 50px;
  border: 1px solid #00a8ff;
  border-radius: 100%;
}

.user-right-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.user-right-container > h6 {
  margin: 0;
  padding: 0;
  padding-right: 5px;
  color: #00a8ff;
}
.user-right-container > p {
  margin: 0;
  padding: 0;
}
.crown{
  width: 30px!important;
  height: 30px!important;
  border: none !important;

}
@media screen and (min-width: 1024px){
  .user-list-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px !important;
  }
  .modal-header-1{
    width: 40vw !important;
  }
  .modal-content{
    width: 40vw!important;
    margin-bottom: -10px!important;
    scrollbar-width: none !important;
    overflow: hidden;
  }
  .modal-content::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none !important;
  }
}