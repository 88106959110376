.multiple-choice-main-container {
  background-color: #00b4d8;
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  margin-bottom: 10px;
}
.multiple-choice-container {
  overflow: hidden;
}
.multiple-choice-cancel-btn {
  font-size: 30px;
  color: #fff;
  margin: 10px auto !important;
  padding: 0;
}
.multiple-choice-title {
  text-transform: uppercase;
  color: #fff;
  font-family: Georgia, "Times New Roman", Times, serif !important;
  font-weight: 900 !important;
  font-size: 35px !important;
  margin: 10px 0 !important;
  padding: 0 !important;
  text-align: center !important;
}
.multiple-choice-sub-title {
  color: #000;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  text-align: center !important;
  padding: 5px 15px !important;
  line-height: 23px !important;
  margin: 0 !important;
}

.next-multiple-choice-icon {
  color: rgb(255, 255, 255);
  margin: 0;
  padding: 0;
}
.multiple-choice-next-question-btn {
  color: #fff;
  outline: none;
  border: 1px solid #ffffff;
  margin: auto;
  display: block;
  align-items: center;
  background-color: transparent;
  margin: 12px auto;
  font-size: 12px;
}
.checkbox-container {
  margin-top: 10% !important;
  border-radius: 35px !important;
  background-color: #fff;
  margin-left: 20px !important;
  margin-right: 20px !important;
  padding: 5px 8px !important;
}
.input-box-container {
  margin: 0px !important;
  padding: 10px 20px !important;
}

.done-show {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  margin: auto !important;
}
.done-hide {
  display: none;
}
.done-btn {
  text-align: center;
  background-color: transparent;
  outline: none;
  border: none;
  width: 120px;
  margin-top: 20px !important;
  height: 40px;
  border-radius: 30px;
  font-weight: bolder;
  color: #fff;
  border: 2px solid #fff;
  font-size: 14px !important;
}
.done-btn:hover {
  background-color: #ffffff;
  opacity: 0.8;
  color: #000;
  border: none;

}
.input-hide {
  display: none;
}
.input-show[type="checkbox"] {
  width: 25px;
  height: 25px;
  accent-color: #00a8ff !important;
  color: #fff !important;
  display: block;
}

.right-wrong-show {
  display: block;
  transition: all ease-in linear;
}
.right-wrong-hide {
  display: none;
}
.checkbox-text {
  font-size: 12px !important;
  line-height: 25px !important;
  color: #000;
  font-weight: bolder !important;
  background-color: #fff;
  margin: 7px 0px !important;
  width: 100% !important;
  padding: 7px 20px !important;
  border-radius: 30px !important;
  text-align: center!important;
}
.disabled-click{
  pointer-events: none;
  cursor: no-drop;
  opacity: 0.75;
}
