.tab-container{
  z-index: 100;
}
.home_main_container{
  transform: translateY(-30px);
}
.section-container {
  align-items: center;
  border-radius: 0px 0px 0px 40px;
  padding: 10px !important;
  /* box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */
  /* box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset; */
  /* margin-bottom: 10px !important; */
  border: none !important;
  border-top: 0 !important;
}
.scroll-container {
  overflow: hidden !important;
}
.date {
  font-size: 10px;
  color: #fff;
}
.book_title {
  font-family: Montserrat, sans-serif !important;
  color: #fff;
  line-height: 22px !important;
}
.book_img {
  width: 100px !important;
  height: 100px !important;
  float: right;
}
.sub_title {
  font-size: 10px;
  color: #fff !important;
  padding: 0 !important;
  margin: 0 !important;
}
.save_icon_1 {
  color: #fff;
  float: right;
  font-size: 15px;
  margin: 0 5px !important;
}
.post-type-icon {
  color: #fff;
  float: right;
  border: 2px solid #fff;
  border-radius: 100% !important;
  width: 25px;
  height: 25px;
  padding: 3px 4px;
  margin: 5px 10px;
}
.home_main_container {
  margin-bottom: 50px !important;
  overflow: hidden !important;
}
.sub-container {
  display: flex;
  align-items: center;
  justify-content: left;
}
.saved_icon {
  color: #00a8ff !important;
  background-color: #fff;
  border: 1px solid #00a8ff;
  border-radius: 50%;
  padding: 5px 0px !important;
  width: 22px;
  height: 22px;
  margin: 0 5px !important;
}
.post_icon{
  margin: 7px 15px 7px 7px;
  color: #fff!important;
}
.default-nav{
  background-color: transparent!important;
  margin: 0 10px;
  color: #fff!important;
}
.react-slideshow-container+ul.indicators {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  left: 50%;
  /* transform: translateX(-40%); */
  z-index: 100;
}
.img_slider{
  width: 100%;
  height: 32vh;
  /* border-radius: 0px 0px 0px 40px; */
}

@media screen and (min-width: 300px) {
  .section-container {
    align-items: center;
    border-radius: 0px 0px 0px 40px;
    padding: 10px !important;
    overflow: hidden !important;
    /* box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px; */
  }
}
@media screen and (min-height: 500px) {
  .img_slider{
    width: 100%;
    height: 30vh;
  }
}
@media screen and (min-height: 667px) {
  .img_slider{
    width: 100%;
    height: 32vh;
  }
}
@media screen and (min-height: 700px) {
  .img_slider{
    width: 100%;
    height: 30vh;
  }
}
@media screen and (min-height: 800px) {
  .img_slider{
    width: 100%;
    height: 28vh;
  }
}
@media screen and (min-width: 500px) {
  .img_slider{
    width: 100%;
    height: 40vh;
  }
}
@media screen and (min-width: 600px) {
  .img_slider{
    width: 100%;
    height: 40vh;
  }
}
@media screen and (min-width: 1024px){
  .home_container{
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 0;
  }
  .home_main_container {
    width: 40vw; 
    overflow: hidden !important;
    border-left: 1px solid #ccc!important;
    border-right: 1px solid #ccc!important;
    background-color: #fff;
    /* box-shadow: 0px 0px 0px 4px rgb(38 38 38 / 53%) !important; */
    margin-bottom: 0px !important;
  }
  
  .date{
    font-size: 10px!important;
    color: #fff!important;
    margin: 0;
    padding: 0;
  }
  .book_title {
    font-family: Montserrat, sans-serif !important;
    color: #fff;
    line-height: 22px !important;
    font-size: 11px !important;
  }
  .book_img {
    width: 80px !important;
    height: 80px !important;
    float: right;
  }
  .saved_icon {
    color: #00a8ff !important;
    background-color: #fff;
    border: 1px solid #00a8ff;
    border-radius: 50%;
    padding: 5px 0px !important;
    width: 20px;
    height: 20px;
    margin: 0 5px !important;
  }
  .save_icon_1 {
    color: #fff;
    float: right;
    font-size: 14px;
    margin: 0 5px !important;
  }
  .sub_title{
    font-size: 10px!important;
  }
  .post_icon{
    margin: 3px 8px;
    color: #fff !important;
  }
  .img_slider{
    width: 100%;
    height: 45vh;
  }
}

