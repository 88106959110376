.footer-container {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  width: 100%;
  height: 90px;
  bottom: 0px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px 15px 0px 0px;
  /* -webkit-box-shadow: -1px -4px 22px 4px rgba(38, 38, 38, 0.53); */
  /* box-shadow: -1px -4px 22px 4px rgba(38, 38, 38, 0.53); */
  border: 1px solid #ccc!important;
  z-index: 100;
  font-family: Montserrat, sans-serif !important;
}

.footer-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  padding-left: 20px;
}
.footer-explore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.footer-saved {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  padding-right: 20px;
}
.footer-name {
  font-size: 12px;
  color: #b1ac9f;
  padding-top: 8px;
}

.active {
  color: #00a8ff;
}

a {
  text-decoration: none !important;
  display: block !important;
}

.tab-container{
  box-shadow: 0px 5px 10px rgb(147, 147, 147);
}
@media only screen and (min-width: 1024px) {
  .main-container{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 14vh !important;
  }

  .footer-container {
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    width: 40vw!important;
    height: 14vh!important;
  }

}