.yesno-main-container {
  background-color: #00b4d8;
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden;
  scroll-behavior: smooth;
  margin-bottom: 10px;
}
.yesno-container {
  padding: 0px 10px !important;
  margin: 20px auto !important;
  overflow: hidden;
}
.yesno-cancel-btn {
  font-size: 30px;
  color: #fff;
  margin: 0;
  padding: 0;
}
.yesno-title {
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  padding: 0;
}
.yesno-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px !important;
}
.yesno-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 10px !important;
  background-color: #fff;
  position: relative;
  padding: 0 !important;
  border-radius: 30px !important;
}
.yesno-question {
  padding: 15px 20px !important;
  margin: 0 !important;
  font-size: 14px !important;
  color: #000 !important;
  font-weight: bold !important;
  text-align: center !important;
}
.yesno-btn-container {
  width: 100%;
  margin: 20px auto !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: center !important;
}
.no-btn {
  display: block;
  outline: none;
  border: none;
  color: #000;
  background-color: #f70f0f;
  margin: 15px auto !important;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  padding: 13px 80px;
  font-weight: bold !important;
  border-radius: 30px !important;
  -webkit-box-shadow: 0px 3px 5px 1px #7a7a7a;
  box-shadow: 0px 3px 5px 1px #7a7a7a;
}
.no-btn-disabled {
  display: block;
  background-color: #f70f0f;
  outline: none;
  border: none;
  color: #000;
  margin: 15px auto !important;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  padding: 13px 80px;
  font-weight: bold !important;
  border-radius: 30px !important;
  -webkit-box-shadow: 0px 3px 5px 1px #7a7a7a;
  box-shadow: 0px 3px 5px 1px #7a7a7a;
  cursor: not-allowed;
  pointer-events: none;
}
.yes-btn:hover,
.no-btn:hover {
  opacity: 0.7 !important;
}
.yes-btn {
  outline: none;
  border: none;
  font-weight: bold !important;
  border-radius: 30px !important;
  margin: 15px auto !important;
  color: #000;
  background-color: #00a8ff;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  padding: 13px 80px;
  -webkit-box-shadow: 0px 3px 5px 1px #7a7a7a;
  box-shadow: 0px 3px 5px 1px #7a7a7a;
}
.yes-btn-disabled {
  outline: none;
  border: none;
  font-weight: bold !important;
  border-radius: 30px !important;
  margin: 15px auto !important;
  color: #000;
  background-color: #00a8ff;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  padding: 13px 80px;
  -webkit-box-shadow: 0px 3px 5px 1px #7a7a7a;
  box-shadow: 0px 3px 5px 1px #7a7a7a;
  cursor: not-allowed;
  pointer-events: none;
}
.yesno-answer-show {
  background-color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 10px !important;
  padding: 0 !important;
  z-index: 20;
  border-radius: 30px !important;
}
.yesno-answer-hide {
  display: none !important;
}
.yesno-answer-show > .yes-icon-show,
.no-icon-show {
  display: block;
  font-size: 50px;
  color: #4bb543;
}
.yesno-answer-show > .no-icon-show {
  display: block;
  font-size: 50px;
  color: #f70f0f;
}
.yes-icon-hide,
.no-icon-hide {
  display: none;
}

@media only screen and (min-width: 992px) {
  .yesno-card {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    padding: 0 !important;
    border-radius: 30px !important;
  }
  .yesno-answer-show {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 10px !important;
    padding: 0 !important;
    border-radius: 30px !important;
  }
  .yesno-answer-hide {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) {
  .yesno-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 10px !important;

    position: relative;
    padding: 0 !important;
    border-radius: 30px !important;
  }
  .yesno-answer-show {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 10px !important;

    padding: 0 !important;
    border-radius: 30px !important;
  }
  .yesno-answer-hide {
    display: none;
  }
}
