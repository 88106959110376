.help-main-container {
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  font-family: Montserrat, sans-serif !important;
}
.help-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 15px;
}
.help-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 10px;
}
.help-notification-icon {
  color: #fff;
  font-size: 20px;
  background-color: #00a8ff;
  border-radius: 5px;
  width: 40px;
  height: 30px;
  padding: 4px 5px;
  margin-right: 10px;
}
.help-divider {
  width: 95%;
  margin-right: 25%;
  margin-left: 5%;
  margin-top: -5px;
}

.help-arrow-right-icon {
  font-size: 20px;
  color: #00a8ff;
}
@media screen and (min-width: 1024px){
  .help_container{
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 0;
  }
  .help_main_container {
    width: 40vw; 
    height: 100vh;
    overflow: hidden !important;
    border-left: 1px solid #ccc!important;
    border-right: 1px solid #ccc!important;
    margin-bottom: 0px !important;
  }
  
}
