.edit-container {
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.img-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -10px;
  position: relative;
}
.profile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
}
.edit-icon {
  background-color: #00a8ff;
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  font-size: 18px;
  padding: 6px 2px;
  position: absolute;
  bottom: 0;
  right: 38%;
}
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}
.edit-input {
  border: 0;
  outline: 0;
  width: 100%;
  border-bottom: 1px solid #c9c8c8;
  margin-top: 10px;
  color: #555;
  margin-bottom: 10px;
}
.update-btn {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  border-radius: 10px;
  color: #00a8ff;
  background: transparent;
  border: 1px solid #00a8ff;
}
@media screen and (min-width: 1024px){
  .edit_main_container{
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 0;
  }
  .edit_container {
    width: 40vw; 
    height: 100vh;
    overflow-y: hidden !important;
    border-left: 1px solid #ccc!important;
    border-right: 1px solid #ccc!important;
  }
  .update-btn{
    margin-top: 20px!important;
  }
}
