.support-main-container {
  display: block;
  width: 100%;
  height: 80vh;
  font-family: Montserrat, sans-serif !important;
}
.support-card-container {
  margin: 20px 10px 0px 10px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.support-image-container {
  width: 300px;
  height: 200px;
  margin: auto;
}
.support-image-container > img {
  width: 100%;
}
.support-card {
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    5px 5px 15px 5px rgba(0, 0, 0, 0) !important;
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0) !important;
}
.support-card:last-child {
  margin-top: 10px;
}
@media screen and (min-width: 1024px){
  .support-container{
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .support-main-container {
    width: 40vw; 
    height: 100vh;
    overflow: hidden !important;
    border-left: 1px solid #ccc!important;
    border-right: 1px solid #ccc!important;
    margin-bottom: 0px !important;
  }
  
}