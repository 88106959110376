.page-main-container{
  padding: 0;
  height: 100vh;
  font-family: 'Montserrat';
  width: 100%;
  overflow: hidden;
  position: relative;
}
@media screen and (min-height: 600px) {
  .page-main-container{
    padding: 0;
    height: 120vh!important;
    font-family: 'Montserrat';
    width: 100%;
    overflow: hidden;
    position: relative;
  }
}
@media screen and (min-height: 700px) {
  .page-main-container{
    padding: 0;
    height: 105vh!important;
    font-family: 'Montserrat';
    width: 100%;
    overflow: hidden;
    position: relative;
  }
}
@media screen and (min-height: 800px) {
  .page-main-container{
    padding: 0;
    height: 100vh!important;
    font-family: 'Montserrat';
    width: 100%;
    overflow: hidden;
    position: relative;
  }
}
.free-trial-main-container {
  margin: 0px !important;
  padding: 0px !important;
  box-sizing: border-box;
  z-index: 100 !important;
  font-family: Montserrat, sans-serif !important;
  margin: 0px 0px -10px -50px!important;
}
.language-tap-container {
  margin: 0px !important;
  padding: 10px 5px !important;
}
.language-tap {
  margin-top: 30px !important;
  width: 100% !important;
  height: 100% !important;
  /* border: 2px solid rgba(44, 255, 249, 0.7796160700608368); */
  border-radius: 10px !important;
}

.nav-pills .nav-link {
  color: #000;
  font-size: 10px;
  border: 1px solid rgba(44, 255, 249, 0.7796160700608368) !important;
  margin-right: 5px !important;
  padding: 5px 8px !important;
}
.terms-condition-container {
  width: 100% !important;
  height: 100px !important;
  margin: 0 0 3px 0 !important;
  padding: 5px 15px !important;
  font-size: 9px !important;
  color: rgb(0, 0, 0);
  font-weight: bolder !important;
}
.terms-condition-container::-webkit-scrollbar {
  display: none !important;
}
.terms-condition-container > li {
  font-size: 10px !important;
  color: #000 !important;
  width: 100% !important;
  height: auto !important;
  text-align: left !important;
  padding: 10px 3px !important;
}
.tab-content {
  margin-top: -25px !important;
  padding: 0 !important;
}
.free-trial-btn {
  border: none;
  outline: none;
  white-space: nowrap !important;
  background: rgb(254, 254, 254);
  background: linear-gradient(60deg, #8a56ac 25%, #8a56ac 42%);
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
  padding: 10px 8px !important;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  width: 90% !important;
  border-radius: 50px;
  margin-top: 10px !important;
  z-index: 100 !important;
}
.landing-carousel-container {
  display: flex;
  align-content: flex-start !important;
  align-self: center !important;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  width: 80% !important;
  height: 80% !important;
}

/* .term-condition-modal {
  width: 100vw !important;
  height: 80vh !important;
  margin: 0px !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.modal-header-2 {
  width: 100vw;
  height: 5vh;
  background-color: #fff;
  border: none !important;
} */
.term-condition-modal > .modal-dialog {
  margin: auto !important;
}
.term-condition-modal {
  width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  align-self: center;
  align-content: center;
}
.modal-header-2 {
  font-family: Montserrat, sans-serif !important;
  width: 90% !important;
  height: 8vh;
  background-color: #fff;
  border: none !important;
}
.term-modal-body {
  overflow: scroll;
  font-family: Montserrat, sans-serif !important;
  padding: 10px 8px !important;
  line-height: 18px !important;
  border: none !important;
  background-color: #fff;
  width: 90% !important;
  height: 80vh !important;
  border-radius: 0px 0px 8px 8px !important;
}
.term-condition-btn {
  background-color: transparent !important;
  border: none !important;
}
.term-condition-btn:hover {
  box-shadow: none !important;
  border: none !important;
}
.landing-content-ul {
  font-family: Montserrat, sans-serif !important;
  margin: 10px 0px;
  padding: 0 !important;
  font-size: 12px;
  color: red;
  text-align: center;
}
.landing-content-ul > li {
  font-family: Montserrat, sans-serif !important;
  font-size: 10px !important;
  list-style: square;
  margin: 0px !important;
  padding: 0px !important;
  color: #fff;
  text-align: left !important;
}
.page-container{
  overflow:scroll;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top:5%;
}
/* media query for website */
@media only screen and (min-width: 1280px) {
  .landing-carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40% !important;
    height: 40% !important;
    top: 30% !important;
    margin-right: 5% !important;
  }
  .landing-carousel-container > .carousel > .carousel-inner {
    padding: 0px;
    margin-left: 20%;
  }

  .landing-carousel-container > .carousel > .carousel-inner > .carousel-item {
    width: 60% !important;
    height: 100% !important;
  }
  /* .modal-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: absolute;
    padding-right: 50% !important;
  } */
  .modal-dialog {
    margin: auto !important;
  }
  .term-condition-modal {
    width: 100% !important;
    height: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    align-self: center;
    align-content: center;
  }
  .modal-header-2 {
    width: 100% !important;
    height: 10vh;
    background-color: #fff;
    border: none !important;
  }
  .term-modal-body {
    background-color: #fff;
    width: 100% !important;
    height: 75vh !important;
    border-radius: 0px 0px 8px 8px !important;
  }
  .page-main-container{
    font-family: 'Montserrat';
    overflow-x:hidden;
    overflow-y: scroll;
    position: relative;
  }
  .page-container{
    overflow:hidden;
  }
}

.swal-button {
  font-family: Montserrat, sans-serif !important;
  white-space: nowrap !important;
  padding: 10px 15px !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 12px !important;
}
.login-text{
  color: rgb(236, 236, 236);
  margin-top: 20px;
  font-size: 12px;
  font-weight: bold;
}
.link-text{
  color: #59DAF0;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

/* @media screen and (min-width: 1024px) {
  .page-main-container{
    font-family: 'Montserrat';
    overflow-x:hidden;
    overflow-y: scroll;
    position: relative;
  }
  .page-container{
    overflow:hidden;
  }
} */
