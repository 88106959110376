
.audioContainer {
    margin: 0px;
    padding: 0;
    overflow: hidden;
    background-color: #5cafe1 !important;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  
  .audio_card {
    scale: 0.9;
    width: 100% !important;
    height: auto !important;
    background-color: white;
    border-radius: 50px !important;
    flex-direction: column;
    margin-bottom: 4em;
    position: absolute;
  }
  .audio_card_header {
    height: calc(100% - 110px);
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: transparent !important;
  }
  .audio-container {
    position: relative;
    padding: 0;
    width: 100%;
    height: auto;
  }
  .audio {
    width: 100%;
    height: 100%;
  }
  .vjs-tech {
    background-color: #00b4d8 !important;
    border: none !important;
    box-shadow: none !important;
    border-width: 0px !important;
    width: 100% !important;
  }
  .audio-js {
    background-color: transparent !important;
  }
  .vjs-poster{
    background-size: 100%!important;
  }
  
  .audio-bottom-container > h6 {
    font-size: 14px;
  } 
  .back-container{
    background-color: #00b4d8!important;
    color: #ddd;
    margin: 0;
    padding: 0;
  }
  .audio-text {
    font-size: 15px !important;
    font-family: Montserrat, sans-serif !important;
    overflow: scroll !important;
  }
  
  @media screen and (min-height: 667px) {
    .audio-text {
      font-size: 14px !important;
      font-family: Montserrat, sans-serif !important;
      overflow: scroll !important;
    }
  }
  @media screen and (min-height: 500px) {
    .audio-text {
      font-size: 13px !important;
      font-family: Montserrat, sans-serif !important;
      overflow: scroll !important;
    }
  }
  @media screen and (min-height: 800px) {
    .audio-text {
      font-size: 15px !important;
      font-family: Montserrat, sans-serif !important;
      overflow: scroll !important;
    }
  }
  @media screen and (min-height: 900px) {
    .audio-text {
      font-size: 16px !important;
      font-family: Montserrat, sans-serif !important;
      overflow: scroll !important;
    }
  }
  @media screen and (min-height: 1000px) {
    .audio-text {
      font-size: 16px !important;
      font-family: Montserrat, sans-serif !important;
      overflow: scroll !important;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .audio-main-container{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #00b4d8;
  
      margin: 0;
      padding: 0;
    }
  
    .audioContainer{
      width: 40vw!important;
  
      border-left: 1px solid #ddd !important;
      border-right: 1px solid #ddd !important;
    }
    .audio_card{
      width: 320px!important;
      height: 520px!important;
    }
  }

  @media screen and (max-height: 770px) {
    .audio_card {
      scale: 0.8;
    }
  }
  @media screen and (max-height: 640px) {
    .audio_card {
      scale: 0.7;
    }
  }
  
  .audio-thumbnail img{
    width: 100%;
    height: 300px;
    border-radius: 50px 50px 0px 0px;
    box-shadow: rgb(79 138 190 / 46%) 0px 11px 20px 6px;
  }
  .audio-title h4{
    font-size: 20px;
    margin-top: 2em;
    font-weight: bolder;
    margin-left: 1.5em;
  }
  .audio-title h5{
    margin-left: 1.5em!important;
    color: grey;
  }

  .audio-control{
    border-radius: 0px 0px 25px 25px;
    width: 100%;
    margin-bottom: 2em;
  }
  .audio-body{
    width: 100%;
    height: 150px;
    overflow-y: scroll;
    scroll-behavior: smooth; 
  }
  .audio-description{
    margin-left: 2em!important;
    margin-right: 2em!important;
    font-weight: 400;
    font-size: 15px;
    margin-top: 25px;
    text-align: justify;
    height: auto;
    display: inline-block;
    margin: 0;
    padding: 0;
    /* animation: scroll 15s linear; */
    /* animation-delay: 3s; */
  }
  /* .audio-description:hover {
    animation-play-state: paused;
  }
  @keyframes scroll {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  } */
