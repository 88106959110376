.saved-book-header-container {
  font-family: Montserrat, sans-serif !important;
  overflow: hidden;
  background-color: #9770e6;
  width: 100%;
}
.saved_book_container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.saved-book-inner-container {
  background-color: #fff;
  border-radius: 0px 0px 0px 60px;
}
.saved-book-inner-container > h4 {
  padding: 30px 20px;
  color: #000000;
  font-weight: bold;
}
.saved-book-list-container {
  overflow-y:scroll !important;
  box-sizing: border-box;
  margin-bottom: 80px !important;
  width: 100%;
}
@media screen and (min-width: 1024px){
  .saved_book_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden !important;
  }
  
  .saved-book-header-container {
    font-family: Montserrat, sans-serif !important;
    overflow: hidden;
    background-color: #9770e6;
    border-left: 1px solid #ccc!important;
    border-right: 1px solid #ccc!important;
    width: 40vw;
  }

  .saved-book-list-container{
    width: 40vw;
    overflow: hidden !important;
    margin-bottom: 0px !important;
  
  }

}
