.user-point-container {
  font-family: Montserrat, sans-serif !important;
  margin: 10px;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.user-point-container > h6 {
  font-family: Montserrat, sans-serif !important;
  margin-bottom: 20px;
  font-weight: bolder;
  color: #00a8ff;
  text-transform: uppercase;
}
.point-container {
  font-family: Montserrat, sans-serif !important;
  margin-top: 10px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.lifex-award-img {
  width: 180px;
  margin-bottom: 20px;

  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(360deg);
  }
}

.point-container > p {
  font-family: Montserrat, sans-serif !important;
  margin-top: 20px;
  font-weight: bold;
  font-size: 14px;
  color: #00a8ff;
  text-align: center;
}
.point-btn {
  font-family: Montserrat, sans-serif !important;
  color: #fff;
  background-color: #00a8ff;
  outline: none;
  border: 2px solid #00a8ff;
  width: 120px;
  height: 40px;
  white-space: nowrap;
  text-align: center;
  border-radius: 50px;
  font-size: 14px;
  margin-top: 20px;
}
.point-btn:hover {
  color: #000;
  background-color: transparent;
  border: 2px solid #00a8ff;
}
