.image-quiz-main-container {
  background-color: #00b4d8;
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  margin-bottom: 10px;
}
.image-quiz-container {
  padding-top: 20px;
  overflow: hidden;
}
.quiz-cancel-btn {
  font-size: 25px;
  color: #fff;
  margin: 0;
  padding: 0;
}
.quiz-title {
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  padding: 0;
}
.quiz-sub-title {
  color: #fff;
  text-align: left;
  font-size: 16px;
  padding: 10px 20px;
  line-height: 28px;
}
.quiz-image-container {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  position: relative;
}
.quiz-image-container > img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 5px;
}
.next-quiz-icon {
  color: #fff;
  margin: 0;
  padding: 0;
}
.quiz-next-question-btn {
  color: #fff;
  outline: none;
  border: 1px solid #ffffff;
  margin: auto;
  display: block;
  align-items: center;
  background-color: transparent;
  margin: 12px auto;
  font-size: 14px;
}
.true-false-show {
  background-color: rgba(0, 0, 000, 0.6);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: all 2s ease-out;
  animation-name: lifex-img;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}
@keyframes lifex-img {
  from {
    transform: scale(0.5);
    border: none;
  }
  to {
    transform: scale(1);
    border: 1px solid #00a8ff;
  }
}

.true-false-show > .true-false-icon {
  color: #00a8ff !important;
  font-size: 35px;
}
.true-false-hide {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  display: none!important;
  justify-content: center;
  align-items: center;
  z-index: 100;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: 1s all ease-in-out;
}
