.saved-section-container {
  font-family: "Montserrat", sans-serif;
  align-items: center;
  border-radius: 0px 0px 0px 40px;
  padding: 10px !important;

}

.date {
  font-size: 10px;
  color: #fff;
}
.book-title {
  font-family: Montserrat, sans-serif;
  color: #fff;
  line-height: 22px !important;
  font-size: 12px !important;
}
.book-img {
  width: 100px !important;
  height: 100px !important;
  float: right;
  margin-right:13px;
}
.sub-title {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  color: #fff;
  padding: 0 !important;
  margin: 0 !important;
}
.saved_icon_2 {
  color: #00a8ff !important;
  background-color: #fff;
  border: 1px solid #00a8ff;
  border-radius: 50%;
  padding: 5px 0px !important;
  width: 22px;
  height: 22px;
  margin: 0 5px 0px 12px !important;
}
.bottom-container {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 10px;
}
.post-type-icon {
  color: #fff;
  float: right;
  border: none !important;
  border-radius: 100% !important;
  width: 25px;
  height: 25px;
  padding: 3px 4px;
  margin: 5px 10px;
}
