.save_modal_container{
  width: 40vw;
}
.save-modal-header {
  width: 140px;
  border-radius: 10px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
  color: #fff;
  padding: 8px !important;
}
.save-modal {
  margin-top: 200px;
}
.save-modal-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  text-align: center;
}
.save-title {
  margin-left: 5px;
}
