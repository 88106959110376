.cancel-modal-main-container {
  position: relative;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  border: none !important;
  background-color: transparent !important;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 0 !important;
}
.cancel-modal-header {
  border-radius: 0px 70px 0px 0px !important;
  width: 300px;
  background-color: #fff !important;
  border-radius: none !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  border: none !important;
}
.cancel-modal-title {
  padding: 10px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
}

.cancel-modal-body {
  height: 80px;
  width: 300px;
  overflow: hidden;
  border: none !important;
}
.cancel-modal-body > p {
  padding: 0px 10px;
}
.cancel-modal-footer {
  border: none !important;
  background-color: #fff !important;
  width: 300px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 0px 0px 0px 70px !important;
}
.cancel-modal-btn {
  border: none;
  outline: none;
  padding: 8px 15px;
  color: #fff;
  background-color: #e87642;
  border-radius: 50px;
}
.unsubscribe-modal-btn {
  border: none;
  outline: none;
  padding: 8px 15px;
  color: #fff;
  background-color: #998fa2;
  border-radius: 50px;
}
@media screen and (min-width: 1024px){
  .cancel-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px !important;
  }
  .modal-dialog{
    width: 40vw!important;
    margin-bottom: -10px!important;
    scrollbar-width: none !important;
    overflow: hidden;
  }
  .modal-content::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none !important;
  }
}