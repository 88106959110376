.terms-modal{
   overflow: hidden !important;
    width: 90%;
    height: 80vh!important;

}
.term_des{
    width: 100% !important;
    height: 65vh !important;
    overflow-y: scroll !important;
}
.term_des::-webkit-scrollbar{
    display: none;
}
.des{
    width: 100%;
    height: 100%;
    font-weight: 400!important;
    text-align: justify;
    height: 100px!important;
}