.loading-container {
  overflow: hidden;
  font-family: Montserrat, sans-serif !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-lifex {
  padding: 10px;
}
