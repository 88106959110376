.slider-main-container {
  width: 100vw !important;
  height: 100vh !important;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column;
  align-items: center !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  font-family: Montserrat, sans-serif !important;
}
.slider-container {
  width: 100% !important;
  display: flex;
  justify-content: center !important;
  align-items: flex-end !important;
  position: relative !important;
  margin: 0px 0px 45% 0px !important;
  padding: 0 !important;
}
.slider-wrapper {
  width: 100% !important;
  height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  float: none !important;
}
.progress-bar-container {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
  align-items: center;
  margin: 15px auto 0px auto !important ;
  padding: 0px;
  flex-direction: row !important;
}
.progress-bar {
  width: 60%;
  height: 2px !important;
  align-items: flex-start;
  background-color: #fff !important;
  margin-right: 20% !important;
}
.slider-x-icon {
  width: 20%;
  font-size: 30px;
  color: #fff;
  display: flex;
  text-align: left !important;
  float: left;
  z-index: 100;
}

.my-card-cover {
  background-color: #fff;
  width: 300px !important;
  height: 510px !important;
  border-radius: 0px 50px 0px 50px;
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  justify-items: center;
  box-shadow: rgba(0, 0, 0, 0.4) 3px 0px 4px,
  rgba(0, 0, 0, 0.3) 0px 7px 0px -7px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  overflow: hidden;
  margin: 5px auto!important;
}
.my-card-cover-img {
  width: 100% !important;
  height: 60% !important;
  align-self: flex-start;
  align-items: center;
}
.my-card-cover-img-full {
  width: 100% !important;
  height: 100% !important;
}
.book-cover-details {
  width: 100% !important;
  height: 40% !important;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  flex-direction: column;
}
.book-cover-details > h4 {
  font-family: Montserrat, sans-serif !important;
  align-self: flex-start;
  color: #000 !important;
  font-size: 14px !important;
  padding: 10px 20px;
  line-height: 24px;
  font-weight: bolder;
  margin: auto;
  text-align: center;
}
.book-cover-details > p {
  text-align: left;
  display: block;
  margin: auto;
  padding: 20px 20px 30px 20px;
  align-self: center;
  color: rgb(182, 179, 179) !important;
  font-size: 14px;
}
.my-card {
  background-color: #fff;
  position: relative;
  width: 300px !important;
  height: 510px !important;
  border-radius: 0px 50px 0px 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 1.25rem;
  margin: 5px auto!important;
  margin-bottom: 6px!important;
  overflow: hidden;
}
.my-card > p {
  width: 100% !important;
  color: #000 !important;
  font-weight: 400;
  align-items: left !important;
  padding: 0 !important;
  margin: 0 !important;
}
.carousel-indicators {
  display: none !important;
}
.carousel-control-prev-icon {
  background-image: url("../../assets/slider-arrow/left-arrow.png") !important;
  position: absolute !important;
  bottom: -50px;
  left: 15vw;
  padding: 5px 15px;
  display: block;
  border: 1px solid white !important;
  border-radius: 50% !important;
  z-index: 100 !important;
}
.carousel-control-next-icon {
  background-image: url("../../assets/slider-arrow/right-arrow.png") !important;
  position: absolute !important;
  bottom: -50px;
  right: 15vw;
  padding: 5px 15px;
  display: block;
  border: 1px solid white !important;
  border-radius: 50% !important;
  z-index: 100 !important;
}
.pin-btn {
  position: absolute;
  bottom: 10px;
  right: 20px;
  margin: 5px 10px;
  outline: none;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  display: block;
}
.book-cover-half {
  width: 100%;
  height: 100%;
  border-radius: 0px 75px 0px 0px;
  padding: 30px;
}
.book-cover-full {
  width: 100%;
  height: 100%;
  border-radius: 0px 40px 0px 40px;
}


.my-card > a {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: auto;
}


.goto-quiz-btn {
  margin-top: 20px !important;
  display: block;
  width: 100%;
  height: 40px !important;
  color: #000;
  font-weight: bold !important;
  align-self: center;
  outline: none;
  border: none !important;
  border-radius: 50px;
  font-size: 15px;
}
.goto-quiz-btn:hover {
  opacity: 0.5 !important;
}
.carousel-control {
  margin-top: -27px;
}
.carousel-control.right {
  left: auto;
  right: 15px;
  background-image: none !important; /*url('/static/images/arrow-right.png')*/
  background-position: 0px 0px;
  background-repeat: no-repeat;
}
.quiz-yes-no-title {
  color: #000 !important;
  justify-content: center;
  display: flex;
  align-items: center;
}
.quiz-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.book-text {
  font-size: 15px !important;
  font-family: Montserrat, sans-serif !important;
  overflow: hidden !important;
}
.yes-no-btn-container {
  width: 100% !important;
  margin: 10px 20px !important;
  padding: 10px 30px !important;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  flex-direction: column !important;
}
.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
  margin-bottom: 20px;
}
@media screen and (min-height: 600px) {
  .slider-container {
    width: 100% !important;
    display: flex;
    justify-content: center !important;
    align-items: flex-end !important;
    position: relative !important;
    margin: 0px 0px 30% 0px !important;
    padding: 0 !important;
    transform: scale(0.9);
  }
}
@media screen and (min-height: 700px) {
  .slider-container {
    width: 100% !important;
    display: flex;
    justify-content: center !important;
    align-items: flex-end !important;
    position: relative !important;
    margin: 0px 0px 40% 0px !important;
    padding: 0 !important;
    transform: scale(1);
  }
}
@media screen and (min-height: 800px) {
  .slider-container {
    width: 100% !important;
    display: flex;
    justify-content: center !important;
    align-items: flex-end !important;
    position: relative !important;
    margin: 0px 0px 50% 0px !important;
    padding: 0 !important;
    transform: scale(1);
  }
}
@media screen and (min-width: 600px) {
  .slider-container {
    width: 100% !important;
    display: flex;
    justify-content: center !important;
    align-items: flex-end !important;
    position: relative !important;
    margin: 0px 0px 50% 0px !important;
    padding: 0 !important;
    transform: scale(0.9);
  }
  .carousel-control-prev-icon {
    background-image: url("../../assets/slider-arrow/left-arrow.png") !important;
    position: absolute !important;
    bottom: -50px;
    left: 35vw;
    padding: 5px 15px;
    display: block;
    border: 1px solid white !important;
    border-radius: 50% !important;
    z-index: 100 !important;
  }
  .carousel-control-next-icon {
    background-image: url("../../assets/slider-arrow/right-arrow.png") !important;
    position: absolute !important;
    bottom: -50px;
    right: 35vw;
    padding: 5px 15px;
    display: block;
    border: 1px solid white !important;
    border-radius: 50% !important;
    z-index: 100 !important;
  }
}

@media screen and (min-height: 1000px) {
  .slider-container {
    width: 100% !important;
    display: flex;
    justify-content: center !important;
    align-items: flex-end !important;
    position: relative !important;
    margin: 0px 0px 40% 0px !important;
    padding: 0 !important;
    transform: scale(1.3);
  }
  .carousel-control-prev-icon {
    background-image: url("../../assets/slider-arrow/left-arrow.png") !important;
    position: absolute !important;
    bottom: -50px;
    left: 30vw;
    padding: 5px 15px;
    display: block;
    border: 1px solid white !important;
    border-radius: 50% !important;
    z-index: 100 !important;
  }
  .carousel-control-next-icon {
    background-image: url("../../assets/slider-arrow/right-arrow.png") !important;
    position: absolute !important;
    bottom: -50px;
    right: 30vw;
    padding: 5px 15px;
    display: block;
    border: 1px solid white !important;
    border-radius: 50% !important;
    z-index: 100 !important;
  }
}

@media screen and (min-height: 1100px) {
  .slider-container {
    width: 100% !important;
    display: flex;
    justify-content: center !important;
    align-items: flex-end !important;
    position: relative !important;
    margin: 0px 0px 50% 0px !important;
    padding: 0 !important;
    transform: scale(1.5);
  }
  .carousel-control-prev-icon {
    background-image: url("../../assets/slider-arrow/left-arrow.png") !important;
    position: absolute !important;
    bottom: -50px;
    left: 30vw;
    padding: 5px 15px;
    display: block;
    border: 1px solid white !important;
    border-radius: 50% !important;
    z-index: 100 !important;
  }
  .carousel-control-next-icon {
    background-image: url("../../assets/slider-arrow/right-arrow.png") !important;
    position: absolute !important;
    bottom: -50px;
    right: 30vw;
    padding: 5px 15px;
    display: block;
    border: 1px solid white !important;
    border-radius: 50% !important;
    z-index: 100 !important;
  }
}

@media screen and (max-width: 300px) {
  .slider-container {
    width: 100% !important;
    display: flex;
    justify-content: center !important;
    align-items: flex-end !important;
    position: relative !important;
    margin: 0px 0px 50% 0px !important;
    padding: 0 !important;
    transform: scale(0.7);
  }
  .my-card,.my-card-cover{
    width: 280px!important;
    height: 480px !important;
  }
  .carousel-control-prev-icon {
    background-image: url("../../assets/slider-arrow/left-arrow.png") !important;
    position: absolute !important;
    bottom: -50px;
    left: 0vw;
    padding: 5px 15px;
    display: block;
    border: 1px solid white !important;
    border-radius: 50% !important;
    z-index: 100 !important;
  }
  .carousel-control-next-icon {
    background-image: url("../../assets/slider-arrow/right-arrow.png") !important;
    position: absolute !important;
    bottom: -50px;
    right: 0vw;
    padding: 5px 15px;
    display: block;
    border: 1px solid white !important;
    border-radius: 50% !important;
    z-index: 100 !important;
  }
}

@media screen and (min-width: 1200px) {
  .slider-container {
    width: 100% !important;
    display: flex;
    justify-content: center !important;
    align-items: flex-end !important;
    position: relative !important;
    margin: 0px 0px 10% 0px !important;
    padding: 0 !important;
    transform: scale(1);
  }
  .carousel-control-prev-icon {
    background-image: url("../../assets/slider-arrow/left-arrow.png") !important;
    position: absolute !important;
    bottom: -50px;
    left: 35vw;
    padding: 5px 15px;
    display: block;
    border: 1px solid white !important;
    border-radius: 50% !important;
    z-index: 100 !important;
  }
  .carousel-control-next-icon {
    background-image: url("../../assets/slider-arrow/right-arrow.png") !important;
    position: absolute !important;
    bottom: -50px;
    right: 35vw;
    padding: 5px 15px;
    display: block;
    border: 1px solid white !important;
    border-radius: 50% !important;
    z-index: 100 !important;
  }
}