
.course_container{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 80px;
}
.terms_container{
    background-color: #fff;
    width: 100%;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #000;
    position: relative;
}
.back{
    position: absolute;
    top: 12%;
    left: 5%;
}
.terms_text{
    width: 90%;
    font-weight: 500;
    font-size: 15px;
    padding: 10px 0;
    text-align: center;
    margin-bottom: 1.5rem;
}
.section-container {
    font-family: "Montserrat", sans-serif;
    align-items: center;
    border-radius: 0px 0px 0px 40px;
    padding: 10px !important;
    position: relative;
    /* box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px; */
}
.discount{
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
    position: absolute;
    top: -3vh;
    right: -4vw;
}
.discount div{
    padding-right: 10px;
}
.discount_amount{
    margin-top: 25px;
    color: #fff;
    font-size: 11px;
    font-weight: 500;
}
.discount_amount-two{
    color: #fff;
    padding: 0;
    margin-top: -5px;
    font-size: 11px;
    font-weight: 500;
}
.description-container{
    display: flex;
    flex-direction: column;
}
.course_main_title {
    font-family: "Montserrat", sans-serif;
    color: #fff;
    font-size: 16px !important;
    font-weight: 500;
}
.point_container{
    width: 26vw;
    height: 33vw;
    border-radius: 10px;
    display: flex;
}
.school_img {
    width: 100% !important;
    height: 75%!important;
    float: right;
    border-radius: 10px 10px 0 0 ;
}
.require_point_container{
    width: 100%!important;
    height: 25%!important;
    background-color: #00A8FF;
    border: 3px solid #00A8FF;
    border-radius: 0 0 10px 10px ;
    
}
.require_point{
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    margin-top: 6px;
}
.sub_title {
    font-size: 10px;
    color: #fff;
    padding: 0 !important;
    margin: 0 !important;
}
.detail_btn{
    font-family: "Montserrat", sans-serif;
    opacity: 0.9;
    width: 80px;
    height: 30px;
    margin-top: 10px;
    border-radius: 30px;
    font-size: 14px;
    color: #fff;
    border:none;
}

/* course detail */
.course_container{
    overflow: auto;
}
/* card header */
.course_detail_container{
    font-family: "Montserrat", sans-serif;
    background-color: #E2F0FF;
    width: 100vw;
    height: 90vh;
    position: relative;
}
.card{
    overflow-y: hidden;
}
.back_btn{
    background-color: transparent;
    width: 15px;
    height: 25px;
    position: absolute;
    top: 5%;
    left: 7%;;
}
.img_container{
    background-color: rgba(0, 0, 0 , 0.3)!important;
    width: 100%;
    margin: 0!important;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.sch_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color:#4973a5;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}
.sch_img{
    max-width: 50px;
    max-height: 50px;
    background-color: rgba(255, 255, 255 , 0.5);
    margin: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    object-fit: contain;

}
.sch_name{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
}
.course_img{
    max-width: 100%;
    min-height: 250px;
    object-fit: cover;
}t
/* card body */
.des_container{
    background-color: #E2F0FF;
    overflow: scroll;
    padding: 10px 20px!important;
}
.left_border{
    position: relative;
}
.left_border::after {
    content: "";
    border-left: 1px solid #ccc;
    height: 80%;
    position: absolute;
    top: 3%;
    right: 4%;
}
.course_title{
    font-size:20px;
    font-weight: 550;
    text-align: center;
}
.label{
    font-size: 14px;
    font-weight: 450;
    margin: 0;
    padding: 0;
    line-height: 2;
}
.des_title{
    font-weight: 500;
    font-size: 18px;
}
.descriptions{
    text-align: justify;
    font-size: 15px;
}
.terms{
    font-size: 14px;
    text-align: justify;
    margin-left: -15px;
}
.claim_container{
    width: 100%;
    height: 10vh;
    position: fixed;
    bottom: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #E2F0FF;
}
.claim_btn{
    width: 90%;
    height: 50px;
    background-color: transparent;
    border-radius: 30px;
    font-weight: 550;
    font-size: 18px;
}
.claim_btn:hover{
    background-color: #00A8FF;
    color: #fff!important;
}

.cancel-code{
    width: 120px;
    font-weight: 500;
}

.swal-title {
  text-align: center;
  font-size: 18px !important;
  font-weight: 500!important;
  font-family: Montserrat, sans-serif !important;
}
.swal-footer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.swal-button.cancel-btn,
.swal-button.confirm-btn{
    width: 100px;
    font-size: 14px !important;
    font-weight: bold !important;
}
.confirm-btn.swal-button--confirm{
    background-color: #00A8FF!important;
}

@media screen and (max-height: 800px) {
    .claim_container{
        width: 100%;
        height: 12vh;
        position: fixed;
        bottom: 12vh;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        background-color: #E2F0FF;
    }
    .claim_btn{
        transform: scale(0.95);
    }
}

@media screen and (min-width: 1000px) {
    .course_container{
        width: 40vw;
        overflow: auto;
    }
    .course_detail_container{
        width: 40vw;
    }
    .claim_container{
        width: 40vw;
    }
}