.nav-container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 110px;
  font-family: Montserrat, sans-serif !important;
}

.tab-container {
  width: 100vw !important;
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
  overflow-y: hidden !important;
  overflow-x: scroll !important;
  position: relative !important;
  flex-flow: row !important;
  height: 100% !important;
  white-space: nowrap !important;
  background-color: #ffffff;
  border-radius: 0px 0px 0px 40px !important;
  margin: 0 !important;
  padding: 0px !important;
}
.tab-container::-webkit-scrollbar {
  display: none !important;
}
.bgcolor{
  width: 100%;
  height: 110px;
  border-right: 1px solid #ddd !important;
  border: none;
  box-shadow: none;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: -1;
}

.profile-img {
  border: 1px solid #ccc;
  width: 50px;
  height: 50px;
  margin: 0 !important;
  padding: 0 !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 100%;
  text-align: center;
  object-fit: cover !important;
}
.profile-name {
  text-align: center;
  font-size: 12px;
  color: #374750;
  padding-top: 3px;
  text-transform: uppercase;
}

.tab-icon {
  border: 1px solid #ccc;
  width: 50px;
  height: 50px;
  padding: 11px 0px;
  border-radius: 100%;
  text-align: center;
}
.img-bgcolor{
  z-index: 100 !important;
  width: 100%;
  height: 50px;
}
/* for laptop */
@media only screen and (min-width: 1024px) {
  .nav-container {
    display: flex;
    justify-content:center;
    align-items: center;

    height: 15vh;
    position:relative;
  }

  .tab-container {
    width: 40vw !important;
    display: flex !important;
    justify-content: space-between !important;
    flex-wrap: wrap !important;
    overflow-y: hidden !important;
    overflow-x: scroll !important;
    position: relative !important;
    flex-flow: row !important;
    height: 100% !important;
    white-space: nowrap !important;
    background-color: #ffffff;
    border-radius: 0px 0px 0px 40px !important;
    margin: 0 !important;
    padding: 0px !important;
    /* box-shadow: -1px -4px 22px 4px rgb(38 38 38 / 53%) !important; */
    border-left: 1px solid #ccc!important;
    border-right: 1px solid #ccc!important;
  }
  .bgcolor{
    width: 40vw;
    height: 15vh;
    border-right: 1px solid #ddd !important;

    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    z-index: -1;
  }
  .tab-icon {
    border: 1px solid #ccc;
    width: 40px;
    height: 40px;
    padding: 9px 0px;
    border-radius: 100%;
    text-align: center;
  }

  .icon-text{
    font-size: 12px;
  }
  .profile-img {
    border: 1px solid #ccc;
    width: 42px;
    height: 42px;
    margin-top: 15px;
  } 

}

