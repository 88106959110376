.faq-main-container {
  display: block;
  width: 100%;
  height: 80vh;
  font-family: Montserrat, sans-serif !important;
}

.faq-tap-container {
  width: 100% !important;
  height: 60px !important;
  margin: 0px !important;
  padding: 0px !important;
}
.faq-tap {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px !important;
  margin-top: 20px !important;
  padding: 0 !important;
}

.nav-pills .nav-link {
  color: #000;
  font-size: 10px;
  border: 1px solid #000 !important;
  margin-right: 5px !important;
  padding: 5px 8px !important;
  font-family: Montserrat, sans-serif !important;
}
.nav-pills .nav-link.active {
  color: #00a8ff !important;
  border: 1px solid #00a8ff !important;
  background-color: transparent !important;
}
.setting-faq-container {
  list-style-type: none !important;
  font-size: 14px !important;
  color: #000 !important;
  width: 100% !important;
  height: auto !important;
  line-height: 26px !important;
  text-align: left !important;
  font-family: Montserrat, sans-serif !important;
  padding: 0 !important;
  margin: 0 !important;
}
.accordion-container {
  width: 100% !important;
  height: 380px !important;
  overflow: scroll !important;
}
.accordion-container::-webkit-scrollbar {
  display: none !important;
}
.accordion-button {
  width: 100% !important;
  height: 60px !important;
  border: 1px solid #1a3d50 !important;
  font-size: 12px !important;
  line-height: 17px !important;
}
.accordion-button:focus {
  background-color: none !important;
}
.accordion-body {
  font-size: 12px !important;
  line-height: 18px !important;
}
.accordion {
  --bs-accordion-active-color: #000 !important;
  --bs-accordion-btn-focus-border-color: transparent !important;
  --bs-accordion-active-bg: #fff !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
}
@media screen and (min-width: 1024px){
  .faq-container{
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 0;
  }
  .faq-main-container {
    width: 40vw; 
    height: 100vh;
    overflow: hidden !important;
    border-left: 1px solid #ccc!important;
    border-right: 1px solid #ccc!important;
    margin-bottom: 0px !important;
  }
  
}