.explore-container {
  width: 100%;
  padding: 10px;
}
.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ded9d9;
  width: 90%;
  margin: auto;
  padding: 5px 0px;
}
.back-to-home {
  color: #fff;
  font-size: 22px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.search-input {
  margin: auto;
  padding: 0px;
  border: none;
  background-color: transparent;
  color: #ded9d9;
  outline: none;
  margin: 0px;
}
::-webkit-input-placeholder {
  color: #ded9d9;
  font-size: 18px;
}
.search-btn {
  outline: none;
  background-color: transparent;
  border: none;
  margin: 0px;
  font-size: 18px;
  color: #ded9d9;
}

.search-result-container {
  width: 100%;
  height: auto;
  margin: 20px auto;
  justify-content: center;
  display: flex;
  align-items: center;
}
.book-card {
  width: 100%;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  align-items: center !important;
  border-radius: 0px 40px 0px 40px;
}
.filter-book-img {
  width: 100%;
  border-radius: 0px 10px 0px 10px;
}
.filter-book-details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  float: left !important;
}
.filter-book-title {
  font-size: 12px;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif !important;
  color: #fff;
  line-height: 22px !important;
}
.filter-book-date {
  margin-top: 10px;
  font-size: 10px;
  color: #fff;
}
.post-type-icon {
  color: #fff;
  float: right;
  border: 2px solid #fff;
  border-radius: 100% !important;
  width: 25px;
  height: 25px;
  padding: 3px 4px;
  margin: 5px 10px;
}
@media screen and (min-width: 1024px){
  .explore_container{
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 0;
  }
  .explore_main_container {
    width: 40vw; 
    overflow: hidden !important;
    margin-bottom: 0px !important;
  }
  
}
.btn-post-type{
  margin-top: 30px;
}
.btn-post-type .btn-book,.btn-video{
    margin-right: 10px!important;
}
.btn-post-type button{
  border: 1px solid #39a2ff!important;
  background-color : rgb(60 98 127)!important;
  color: white!important;
  border-radius: 50px;
  padding-top : 7px;
  padding-bottom : 7px;
  padding-left : 25px;
  padding-right : 25px;
}
.book-icon{
  color: white;
  margin-right : 8px;
}
/* .btn:hover{
  color: rgb(60 98 127)!important;
  border: 1px solid rgb(60 98 127)!important;
  background-color : #bbb!important;
} */
.btn.active{
  color: rgb(60 98 127)!important;
  border: 1px solid rgb(60 98 127)!important;
  background-color : #bbb!important;
}

.icon{
  width: 20px!important;
  height: 20px !important;
  margin-right: 5px !important;
}
@media screen and (max-width: 400px) {
  .btn-post-type button{
    border: 1px solid #39a2ff;
    background-color : rgb(60 98 127);
    color: white;
    border-radius: 50px;
    padding-top : 7px;
    padding-bottom : 7px;
    padding-left : 20px;
    padding-right : 20px;
  }
}
@media screen and (max-width: 360px) {
  .btn-post-type button{
    border: 1px solid #39a2ff;
    background-color : rgb(60 98 127);
    color: white;
    border-radius: 50px;
    padding-top : 7px;
    padding-bottom : 7px;
    padding-left : 18px;
    padding-right : 18px;
  }
  .btn-post-type .btn-book,.btn-video{
    margin-right: 5px!important;
}
}