.about-main-container {
  margin: 15px 0 !important;
  display: block;
  width: 100%;
  height: 80vh;
}

.about-tap-container {
  width: 80% !important;
  height: 60px !important;
  margin: 0px !important;
  padding: 0px !important;
}
.about-tap {
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px !important;
  margin-top: 20px !important;
}

.nav-pills .nav-link {
  color: #000;
  font-size: 10px;
  border: 1px solid #000 !important;
  margin-right: 5px !important;
  padding: 5px 8px !important;
  font-family: Montserrat, sans-serif !important;
}
.nav-pills .nav-link.active {
  color: #00a8ff !important;
  border: 1px solid #00a8ff !important;
  background-color: transparent !important;
}
.setting-about-container {
  list-style-type: none !important;
  font-size: 14px !important;
  color: #000 !important;
  width: 100% !important;
  height: auto !important;
  line-height: 26px !important;
  text-align: left !important;
  font-family: Montserrat, sans-serif !important;
  padding: 0 !important;
  margin: 0 !important;
}
.about-content {
  /* margin-top: -25px !important; */
  padding: 0 !important;
}
.copyright-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 50px;
}
.copyright-text {
  font-family: Montserrat, sans-serif !important;
  font-size: 11px;
  color: #fff;
}
@media screen and (min-width: 1024px){
  .about-main-container {
    width: 40vw!important; 
    overflow: hidden !important;
    border-left: 1px solid #ccc!important;
    border-right: 1px solid #ccc!important;
    margin: 0;
    padding:0;
  }
}
