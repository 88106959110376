.setting-main-container {
  position: relative;
  overflow: hidden;
  /* margin: 10px auto !important; */
  font-family: Montserrat, sans-serif !important;
}
.setting-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 15px;
}
.item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 10px;
}
.notification-icon {
  color: #fff;
  font-size: 20px;
  background-color: #00a8ff;
  border-radius: 5px;
  width: 30px;
  height: 35px;
  padding: 2px 4px;
  margin-right: 10px;
}
.items-divider {
  width: 95%;
  margin-right: 25%;
  margin-left: 5%;
  margin-top: -5px;
}

.settings-arrow-right-icon {
  font-size: 20px;
  color: #00a8ff;
}
.feedback-input::placeholder {
  font-size: 12px;
  font-family: Montserrat, sans-serif !important;
}
.feedback-input {
  font-size: 12px;
  font-family: Montserrat, sans-serif !important;
}
@media screen and (min-width: 1024px){
  .setting-main-container{
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 0;
  }
  .setting-container {
    width: 40vw; 
    height: 100vh!important;
    overflow: hidden !important;
    border-left: 1px solid #ccc!important;
    border-right: 1px solid #ccc!important;
  }
}