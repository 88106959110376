.control-panel {
    padding: 0px 0;
    display: flex;
    justify-content: space-evenly;
  }
  
  .timer {
    font-size: 10px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .control-panel img{
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-top: 14px;
  }