.earn_main_container{
  transform: translateY(-35px);
  margin-bottom: 50px !important;
  overflow-x: hidden;
}
.section-container {
  align-items: center;
  border-radius: 0px 0px 0px 40px;
  padding: 10px !important;
  /* box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px; */
}
.date {
  font-size: 10px;
  color: #fff;
}
.book_title {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  line-height: 22px !important;
  font-size: 12px !important;
}
.book_img {
  width: 100px !important;
  height: 100px !important;
  float: right;
}
.sub_title {
  font-size: 10px;
  color: #fff;
  padding: 0 !important;
  margin: 0 !important;
}
.save_icon {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 3px 5px;
  width: 22px;
  height: 22px;
  margin: 0px 5px;
}
.saved_icon_1 {
  background-color: #fff;
  border: 1px solid #00a8ff;
  border-radius: 50%;
  color: #00a8ff;
  padding: 3px 5px;
  margin-right: 6px;
  width: 22px;
  height: 22px;
}
.bottom-container {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 10px;
}
/* .post-type-icon {
  color: rgb(211, 22, 22) !important;
  float: right;
  border: 2px solid #fff;
  border-radius: 100% !important;
  width: 25px;
  height: 25px;
  padding: 3px 4px;
  margin: 5px 10px;
} */
.post_icon{
  margin: 7px 15px 7px 7px;
  color: #fff!important;
}



.earn_container .btn-slick{
  font-family: "Montserrat", sans-serif!important;
  background-color:white!important;
  border: 1px solid #00b4d8!important;
  border-radius: 50px!important;
  font-size: 12px!important;
  font-weight: 500;
  border: none;
  /* box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%); */
}
.earn_container .btn-slick.active{
  background: #00b4d8!important;
  border-radius: 50px!important;
  font-weight: 500;
  border: none;
  /* box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%); */

}
.slick_title{
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
}
.tab-container{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}
/* .tab-container{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
} */
@media screen and (min-height: 600px) {
  .earn_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 32vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:16px 20px 5px 20px;
    font-size: 12px;
    line-height: 22px;
  }
}
@media screen and (min-height: 700px) {
  .earn_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 27vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:16px 20px 5px 20px;
    font-size: 12px;
    line-height: 22px;
  }
}
@media screen and (min-height: 800px) {
  .earn_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 23vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;
  }
}
@media screen and (min-height: 890px) {
  .earn_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 21.5vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;

  }
}
@media screen and (min-height: 900px) {
  .earn_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 21vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;

  }
}
@media screen and (min-height: 1000px) {
  .earn_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 19vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;

  }
}
@media screen and (min-height: 1300px) {
  .earn_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 16vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;

  }
}
@media screen and (max-width: 280px) {
  .earn_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 36vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;
  }
}

@media screen and (min-width: 1024px){
  .earn_container{
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 0;
  }
  .earn_main_container {
    width: 40vw; 
    overflow: hidden !important;
    border-left: 1px solid #ccc!important;
    border-right: 1px solid #ccc!important;
    margin-bottom: 0px !important;
  }
  .date{
    font-size: 10px!important;
    color: #fff!important;
    margin: 0;
    padding: 0;
  }
  .book_title {
    font-family: Montserrat, sans-serif !important;
    color: #fff;
    line-height: 22px !important;
    font-size: 11px !important;
  }
  .book_img {
    width: 80px !important;
    height: 80px !important;
    float: right;
  }
  .saved_icon {
    color: #00a8ff !important;
    background-color: #fff;
    border: 1px solid #00a8ff;
    border-radius: 50%;
    padding: 5px 0px !important;
    width: 20px;
    height: 20px;
    margin: 0 5px !important;
  }
  .save_icon_1 {
    color: #fff;
    float: right;
    font-size: 14px!important;
    margin: 0 5px !important;
  }
  .sub_title{
    font-size: 10px!important;
  }
  .post_icon{
    margin: 3px 7px;
  }
  .earn_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 33vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;
  }
}
@media screen and (min-width: 1200px) {
  .earn_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 25vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;
  }
}
@media screen and (min-width: 1300px) {
  .earn_container .filter-container{
    background-color: #fff;
    width: 100%;
    height: 30vh;
    padding-top: 35px;
  }
  .slick_title{
    padding:20px 25px 5px 25px;
    font-size: 12px;
    line-height: 22px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .slick-slider{
    margin-top: 10px;
  }
  .slick_title{
    padding: 0px 15px;
    padding-top: 17px;
  }
}
@media (min-width: 992px) and (max-width: 1023px) {
  .slick-slider{
    margin-top: 37px;
  }
  .slick_title{
    padding: 0px 20px;
    padding-top: 37px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .slick-slider{
    margin-top: 37px;
  }
  .slick_title{
    padding: 0px 20px;
    padding-top: 37px;
  }
}
@media (min-width: 678px) and (max-width: 768px) {
  .slick-slider{
    margin-top: 32px;
  }
  .slick_title{
    padding: 0px 20px;
    padding-top: 32px;
  }
}
@media (min-width: 462px) and (max-width: 677px) {
  .slick-slider{
    margin-top: 18px;
  }
  .slick_title{
    padding: 0px 20px;
    padding-top: 24px;
  }
}
@media (min-width: 392px) and (max-width: 461px) {
  .slick-slider{
    margin-top: 7px!important;
  }
  .slick_title{
    font-size: 11px;
    padding: 0px 20px;
    padding-top: 15px!important;
  }
  .btn-slick{
    font-size: 11px!important;
    /* box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%); */
  }
}
@media (min-width: 328px) and (max-width: 391px) {
  .slick-slider{
    margin-top: 16px!important;
  }
  .slick_title{
    padding: 0px 20px;
    padding-top: 20px;
    font-size: 10px;
  }
  .btn-slick{
    font-size: 11px!important;
    /* box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%); */
  }
}
@media (min-width: 307px) and (max-width: 328px) {
  .slick-slider{
    margin-top: 17px;
  }
  .slick_title{
    padding: 0px 20px;
    padding-top: 17px;
    font-size: 10px;
  }
  .btn-slick{
    font-size: 9px!important;
    /* box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%); */
  }
}
@media (min-width: 250px) and (max-width: 306px) {
  .slick-slider{
    margin-top: 0px;
  }
  .slick_title{
    padding: 0px 20px;
    padding-top: 14px;
    font-size: 10px;
  }
  .btn-slick{
    font-size: 9px!important;
    /* box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%); */
  }
}
.star-color{
  color: yellow;
}
.star-color-light{
  color: rgb(240, 238, 238);
}