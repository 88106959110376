.main-container {
  overflow: hidden;
  font-family: Montserrat, sans-serif !important;
}
.top-container {
  background-color: #241332;
}
.top-inner-container {
  background-color: #fff;
  border-radius: 0px 0px 0px 50px;
  padding: 20px 30px;
}
.manage-bottom-container {
  background-color: #241332;
  margin: 0;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0px 0px 0px 50px;
}
.bottom-inner-container {
  background-color: #00a8ff;
  width: 100%;
  padding: 30px;
  border-radius: 10px 50px 10px 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.title {
  font-size: 18px;
  color: #fff;
  align-self: left;
  font-weight: 800;
  align-self: flex-start;
  margin-bottom: 10px;
}
ul {
  margin: 0;
  padding: 0;
}
.bottom-inner-container > ul > li {
  color: rgb(249, 249, 249);
  font-size: 14px;
}

.cancel-btn {
  display: block;
  width: 80%;
  margin-top: 30px;
  font-size: 14px;
  color: #ffffff;
  background-color: #1a3d50;
  border: none;
  outline: none;
  padding: 10px 15px;
  border-radius: 30px;
}
.invoice-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px 20px;
  width: 100%;
}
.left-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-icon {
  background-color: #00a8ff;
  padding: 6px 1px;
  height: 30px;
  width: 30px;
  font-size: 30px;
  color: #fff;
  border-radius: 50px;
  margin: 0;
}

.invoice-container > .left-container > h6 {
  font-size: 16px;
  margin: 0;
  padding-left: 10px;
}
.all-btn {
  display: block;
  border: 1px solid #8a56ac;
  padding: 5px 20px;
  color: #8a56ac;
  outline: none;
  border-radius: 50px;
  background-color: #fff;
  font-size: 14px;
}
@media screen and (min-width: 1024px){
  .manage-container{
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .manage-main-container {
    width: 40vw; 
    height: 100vh;
    overflow: hidden !important;
    border-left: 1px solid #ccc!important;
    border-right: 1px solid #ccc!important;
    margin-bottom: 0px !important;
  }
  
}