.notification-header-container {
  position: relative;
  overflow: hidden;
}
.notification-header-container {
  background-color: #00a8ff;
}
.notification-header-inner-container {
  background-color: #fff;
  border-radius: 0px 0px 0px 50px;
  padding: 20px 30px;
}
